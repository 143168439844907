type PayloadType = {} | string;

type CloudFetchOptions = {
  method?: string
  body?: BodyInit | PayloadType;
  headers?: HeadersInit;
  credentials?: string;
  query?: Record<string, string>;
}

type InterceptorArgs = {
  url: string;
  data: CloudFetchOptions;
};

function isGet(method: string | undefined) {
  return !method || method.toLowerCase() === 'get';
}

function hasHeader(headers: HeadersInit, headerName: string) {
  return Object.keys(headers).some(function (_headerName) {
    return _headerName.toLowerCase() === headerName.toLowerCase();
  });
}

function bodyIsLikelyJson(body: BodyInit | PayloadType | undefined) {
  if (!body) {
    return false;
  }

  if (body instanceof FormData) {
    return false;
  }

  if (typeof body === 'number') {
    return false;
  }

  return typeof body !== 'string';
}


function queryArgumentToUrlForGetRequestInterceptor(opts: InterceptorArgs) {
  const data = opts.data;
  const { query } = data;
  let url = opts.url;

  if (isGet(data.method) && query) {
    url +=
      '?' +
      Object.keys(query)
        .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(query[key]))
        .join('&');
  }

  return {
    data,
    url,
  };
}

function includeCsrfTokenInterceptor(opts: InterceptorArgs) {
  const data = opts.data;
  const headers = ((data.headers || {}) as Record<string, string>);
  if (!isGet(data.method)) {
    const tokenElem = document.querySelector('meta[name="x-csrf-token"]');
    if (!tokenElem) {
      throw new Error(
        'Expected meta name="x-csrf-token" to be in the DOM, but could not find it'
      );
    }

    const token = tokenElem.getAttribute('content');
    if (!token) {
      throw new Error(
        'Expected content attr to be part of the meta element, but it was not'
      );

    }
    headers['x-csrftoken'] =  token;
    data.headers = headers;
  }

  return {
    ...opts,
    data,
  };
}

function setDefaultCredentialsInterceptor(opts: InterceptorArgs) {
  const data = opts.data;
  data.credentials = data.credentials || 'include';

  return {
    ...opts,
    data,
  };
}

function setDefaultHeadersInterceptor(opts: InterceptorArgs) {
  const data = opts.data;
  const headers = ((data.headers || {}) as Record<string, string>);

  if (!hasHeader(headers, 'accept')) {
    headers.accept = 'application/json';
    headers['x-requested-with'] = 'fetch';
  }

  if (data.body) {
    if (
      !hasHeader(headers, 'content-type') &&
      bodyIsLikelyJson(data.body)
    ) {
      headers['content-type'] = 'application/json';
    }
  }

  data.headers = headers;

  return {
    ...opts,
    data,
  };
}

function stringifyJsonBodyInterceptor(opts: InterceptorArgs) {
  const data = opts.data;

  if (data.body) {
    data.body = bodyIsLikelyJson(data.body)
      ? JSON.stringify(data.body)
      : data.body;
  }

  return {
    ...opts,
    data,
  };
}

const requestInterceptors = [
  queryArgumentToUrlForGetRequestInterceptor,
  includeCsrfTokenInterceptor,
  setDefaultCredentialsInterceptor,
  setDefaultHeadersInterceptor,
  stringifyJsonBodyInterceptor,
];

export class FetchError extends Error {
  response: Response;

  constructor(msg: string, response: Response) {
    super(msg);
    this.response = response;
  }
}

export async function cloudFetch(url: string, data: CloudFetchOptions = {}) {
  data = Object.assign({}, data);
  if (typeof data !== 'string') {
    data.headers = Object.assign({}, data.headers);
  }

  requestInterceptors.forEach(function (requestInterceptor) {
    const result = requestInterceptor({
      url,
      data,
    });

    url = result.url;
    data = result.data;
  });

  // @ts-expect-error 2345 data.body is converted to a string via the
  // stringifyJsonBodyInterceptor.
  const response = await window.fetch(url, data);
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  const defaultErrorMessage = `Fetch error with status code ${response.status}`;
  throw new FetchError(response.statusText || defaultErrorMessage, response);
}

export async function getJSON<T>(url: string, data?: CloudFetchOptions) {
  const response = await cloudFetch(url, data);
  return response.json() as T;
}

export async function getHTML(url: string, data?: CloudFetchOptions) {
  data = Object.assign({}, data);
  data.headers = Object.assign({}, data.headers) as Record<string, string>;

  if (!hasHeader(data.headers, 'accept')) {
    data.headers.accept = 'text/html';
  }

  const response = await cloudFetch(url, data);
  return await response.text();
}

export async function postForm(url: string, data?: CloudFetchOptions) {
  data = Object.assign({}, data);
  data.headers = Object.assign({}, data.headers) as Record<string, string>;
  data.method = 'POST';

  // FormData headers are automatically set by fetch
  const isFormData = data.body instanceof FormData;
  if (!isFormData && !hasHeader(data.headers, 'content-type')) {
    data.headers['content-type'] = 'application/x-www-form-urlencoded';
  }

  return cloudFetch(url, data);
}

export function postJSON(url: string, data: CloudFetchOptions) {
  return cloudFetch(url, { method: 'POST', ...data });
}

export function updateJSON(url: string, data: CloudFetchOptions) {
  return cloudFetch(url, { method: 'PATCH', ...data });
}
